import React, { useEffect, useState } from "react";
import Card from "../../components/card/Card";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ourTeam from "../../assets/images/our-team.png";
import clientExpectations from "../../assets/images/client-expectations.png";
import "../../assets/styles/style.css";
import APIService from "../../utils/APIService";
import Loader from "../../components/loader/Loader";
import happyLogo from "../../assets/images/logo.svg";
import { scrollToId } from "../../utils/Helper";

const Home = () => {
  const [blogsList, setBlogsList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [categoryId, setCategoryId] = useState(null);
  const [tagId, setTagId] = useState(null);
  const [blogsLoading, setBlogsLoading] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.scrollTo(0, 0);
  //   }, 100);
  //   fetchBlogList(1);
  // }, []);

  useEffect(() => {
    fetchBlogList(1);
  }, [categoryId, tagId]);

  const fetchBlogList = async (page) => {
    try {
      // Set loading states
      setBlogsLoading(true);
      setLoading(true);

      // Construct the API URL
      const url = `/blogs?page=${page}&limit=4${
        categoryId ? `&category=${categoryId}` : ""
      }${tagId ? `&tag=${tagId}` : ""}&status=published`;

      // Fetch data from API
      const response = await APIService.get(url);

      // Filter out drafted blogs
      const blogsData = response.blogs

      // Update state
      setBlogsList((prevBlogs) => [...prevBlogs, ...blogsData]);
      setCurrentPage(response.currentPage);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      // Reset loading states
      setLoading(false);
      setBlogsLoading(false);
    }
  };

  const loadMoreBlogs = () => {
    if (currentPage < totalPages) {
      fetchBlogList(currentPage + 1);
    }
  };

  const handleCategoryChange = (id) => {
    setCategoryId(id);
    setBlogsList([]); // Clear the list when changing categories
    setCurrentPage(1); // Reset the page number
  };

  const handleTagChange = (id) => {
    setTagId(id);
    setBlogsList([]); // Clear the list when changing tags
    setCurrentPage(1); // Reset the page number
  };


  return (
    <div className="wrapper">
      <Header isBackEnable={false} />
      <main>
        <section className="join-sec text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content-block">
                  <h1 className="title h1">
                    Master Client Expectations and Deliver Projects on Time
                  </h1>
                  <p>
                    Join the waitlist for Happy Ending and transform the way you
                    manage client expectations, change orders, and project
                    feedback.
                  </p>
                  <a href="#wishlist" onClick={(e) => scrollToId(e, "wishlist")} className="btn">
                    Join the Waitlist
                  </a>
                </div>
              </div>
              <div className="col-12">
                <div className="img-block">
                  <img src={ourTeam} alt="Our Team" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="client-expectations-sec img-with-text">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className="img-block text-center">
                  <img src={clientExpectations} alt="Client Expectations" />
                </div>
              </div>
              <div className="col-6">
                <div className="content-block">
                  <h2 className="title h2">
                    Master Client Expectations with Happy Ending
                  </h2>
                  <p>
                    Say goodbye to the chaos of mismanaged client expectations
                    and project delays. Happy Ending helps you handle
                    out-of-scope changes, ensure timely client feedback, and
                    keep your projects on track and profitable. Sign up for our
                    waitlist and be among the first to deliver world-class
                    service with ease.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="post-sec">
          <div className="container">
            <h2 className="title h2">
              Insights on Delivering Exceptional Client Service
            </h2>
            <div className="row">
              <div className="col-8 post-main">
                {blogsLoading ? (
                  <Loader />
                ) : (
                  <>
                    {blogsList && blogsList.length > 0 ? (
                      <>
                        {" "}
                        <ul className="row">
                          {blogsList?.map((post, index) => (
                            <Card post={post} key={index} />
                          ))}
                        </ul>
                        <button
                          to="/"
                          className="btn fluid"
                          style={{
                            display:
                              currentPage >= totalPages ? "none" : "block",
                          }}
                          onClick={() => {
                            loadMoreBlogs();
                          }}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <div
                              className="spinner-border text-success"
                              style={{ width: "22px", height: "22px" }}
                              role="status"
                            ></div>
                          ) : (
                            "Load More"
                          )}
                        </button>
                      </>
                    ) : (
                      <div className="card-no-data">
                        <div className="happy-logo-img">
                          <img src={happyLogo} alt="Happy logo" />
                          <p>Sorry, we couldn't find any result</p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="col-4 post-aside">
                <Sidebar
                  onCategoryChange={handleCategoryChange}
                  onTagChange={handleTagChange}
                />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
